const latexCode = `\\documentclass{article}
\\usepackage{graphicx} % Required for inserting images

\\title{Welcome to arxtect}
\\author{arxtect}
\\date{2024}

\\begin{document}

\\maketitle

\\section{Introduction}

\\end{document}
`;

export default latexCode;
